<template>
  <div class="empty-container">
    <img :src="emptySvg" />

    <div class="info">
      <div class="title">OOPS!</div>
      <span>{{ $t("empty") }}</span>
      <div class="back-buttons">
        <el-button class="back-button" type="primary" round @click="$router.go(-1)" size="small">
          {{ $t("handle.backLast") }}
        </el-button>
        <el-button class="back-button" type="primary" round @click="$router.push({ path: 'home' })" size="small">
          {{ $t("handle.backHome") }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import empty from "@/assets/illustration/notFound.svg";

export default {
  data() {
    return {
      emptySvg: empty
    };
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.empty-container {
  text-align: center;
  width: 600px;
  margin: 100px auto;
  @include flex();

  img {
    width: 300px;
    height: 200px;
  }

  .info {
    font-size: 12px;
    text-align: left;
    .title {
      font-size: 32px;
      line-height: 40px;
      color: $color-primary;
      font-weight: 700;
      margin-bottom: 20px;
    }

    span {
      color: $color-sub;
    }

    .back-buttons {
      margin-top: 20px;
    }
  }
}
</style>
